<template>
  <div class="invoice--wrapper pa-4" v-if="documentContent">
    <div class="invoice--watermark" v-if="documentContent.paid_at">
      <div class="inner--watermark">
        <img
          src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/icon-transparent.png"
        />
        <div>{{ $t('invoice.paid') }}</div>
      </div>
    </div>
    <div class="row align-center">
      <div class="col-auto">
        <img :src="logoUrl" alt="Rentfix Logo" height="50" />
      </div>
      <div class="col text-center">
        <div class="documentContent--title" v-if="documentContent.listing_type === 'R'">
          {{ $t('invoice.rentInvoice') }}
        </div>
        <div class="documentContent--title" v-else-if="documentContent.listing_type === 'S'">
          {{ $t('invoice.buyInvoice') }}
        </div>
      </div>
    </div>
    <div class="top--section my-4">
      <div class="row">
        <div class="col-4 px-5">
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.date') }}:</div>
            <div>{{ $date.format(documentContent.created_at, 'dd MMMM yyyy') }}</div>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-6 px-5">
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.invoiceNumber') }}:</div>
            <div>{{ documentContent.invoice_number }}</div>
          </div>
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.ownerName') }}:</div>
            <div>{{ documentContent.owner_name }}</div>
          </div>
          <div class="d-flex justify-space-between mb-1">
            <div class="bold--text">{{ $t('invoice.applicantName') }}:</div>
            <div>{{ documentContent.applicant_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle--section mb-4">
      <div class="bg--grey px-3 py-2">
        <div class="documentContent--title" v-if="documentContent.listing_type === 'R'">
          {{ $t('invoice.rentSummary') }}
        </div>
        <div class="documentContent--title" v-else-if="documentContent.listing_type === 'S'">
          {{ $t('invoice.buySummary') }}
        </div>
      </div>
      <div class="px-3 pb-2">
        <div class="pa-4" v-if="documentContent.listing_type === 'R'">
          <div class="mb-3">
            {{ $t('general.propertyType') }}: {{ documentContent.property_type }}
          </div>
          <div class="mb-3">{{ $t('general.address2') }}: {{ documentContent.address }}</div>
          <div class="mb-3">{{ $t('general.rentPeriod') }}: {{ documentContent.period }}</div>
          <div>{{ $t('general.rentDuration') }}: {{ documentContent.duration }}</div>
        </div>
        <div class="pa-4" v-else-if="documentContent.listing_type === 'S'">
          <div class="mb-3">
            {{ $t('general.propertyType') }}: {{ documentContent.property_type }}
          </div>
          <div>{{ $t('general.address2') }}: {{ documentContent.address }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="bottom--section mb-1">
          <div class="bg--grey px-3 py-2">
            <div class="documentContent--title" v-if="documentContent.listing_type === 'R'">
              {{ $t('invoice.transactionSummary') }}
            </div>
            <div class="documentContent--title" v-else-if="documentContent.listing_type === 'S'">
              {{ $t('invoice.reservationSummary') }}
            </div>
          </div>
          <div class="pa-3">
            <div class="row mb-3 pa-0">
              <div class="col-6">
                <span v-if="documentContent.listing_type === 'R'">{{
                  $t('addListing.rentPrice')
                }}</span>
                <span v-else-if="documentContent.listing_type === 'S'">{{
                  $t('addListing.sellPrice')
                }}</span>
              </div>
              <div class="col-6">
                <span v-if="documentContent.listing_type === 'R'">{{
                  $n(documentContent.rent_price, 'currency', 'id-ID')
                }}</span>
                <span v-else-if="documentContent.listing_type === 'S'">{{
                  $n(documentContent.sell_price, 'currency', 'id-ID')
                }}</span>
              </div>
            </div>
            <div class="row mb-3 pa-0" v-if="documentContent.promo_discount">
              <div class="col-6">
                {{ $t('invoice.promoDiscount') }}
              </div>
              <div class="col-6 documentContent--promo">
                <div>- {{ $n(documentContent.promo_discount, 'currency', 'id-ID') }}</div>
              </div>
            </div>
            <div
              class="row"
              v-if="documentContent.listing_type === 'R' || documentContent.booking_fee"
            >
              <div class="col-6">
                <span class="bold--text" v-if="documentContent.listing_type === 'R'">{{
                  $t('invoice.finalRentPrice')
                }}</span>
                <span class="bold--text" v-else-if="documentContent.listing_type === 'S'">{{
                  $t('invoice.reservationPrice')
                }}</span>
              </div>
              <div class="col-6">
                <span class="bold--text" v-if="documentContent.listing_type === 'R'">{{
                  $n(documentContent.pay_amount, 'currency', 'id-ID')
                }}</span>
                <span class="bold--text" v-else-if="documentContent.listing_type === 'S'">{{
                  $n(documentContent.booking_fee, 'currency', 'id-ID')
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="total--section py-2 px-3 bg--grey">
          <div class="row pa-0 align-center">
            <div class="col-6 documentContent--subtitle">
              <div>{{ $t('invoice.paidTotal') }}</div>
            </div>
            <div class="col-6 bold--text">
              {{ $n(documentContent.pay_amount, 'currency', 'id-ID') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="paid--section row mt-4">
      <div class="col-6"></div>
      <div class="col-6 px-4" v-if="documentContent.paid_at">
        {{ $t('invoice.paidAt') }}:
        {{ $date.format(documentContent.paid_at, 'dd MMMM yyyy HH:mm') }}
      </div>
    </div>
    <div class="footer--section">Invoice: {{ documentContent.invoice_number }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      logoUrl: (state) => state.global.logoUrl,
      isLoggedIn: (state) => state.global.loggedIn,
      token: (state) => state.global.token,
      documentContent: (state) => state.v2.booking.detail.transactionDocument.document,
    }),
  },
  mounted() {
    // console.log('mountedd: ', this.documentContent);
    // this.$store.dispatch('v2/booking/detail/transactionDocument/getInvoice', {
    //   orderNumber: this.$route.query.order_number,
    //   token: this.isLoggedIn ? this.token : this.$route.query.token,
    //   feKey: this.$route.query.fe_key,
    // });
  },
};
</script>
